export default {
    formatNumber(number, decimal) {
        const numberStr = number.toString();

        // Regular expression to add or truncate decimals
        const regex = new RegExp(`^([-]?\\d+(\\.\\d{0,${decimal}})?).*$`);

        // Replace the original number with the formatted one
        return numberStr.replace(regex, "$1");
        // return Math.floor(parseFloat(num) * Math.pow(10, decimal))/Math.pow(10, decimal);
    },

    formatNumberWithCommas(num, decimal) {
        // let number = this.formatNumber(num, decimal);
        // let nStr = number.toString();
        // var x = nStr.split('.')
        // var x1 = x[0]
        // var x2 = x.length > 1 ? '.' + x[1] : ''
        // var rgx = /(\d+)(\d{3})/
        // while (rgx.test(x1)) {
        //     x1 = x1.replace(rgx, '$1' + ',' + '$2')
        // }
        // return x1 + x2

        let formatNum = Math.floor(num * Math.pow(10,decimal)) / Math.pow(10,decimal);

        return formatNum.toFixed(decimal).toString().toLocaleString('en-US',{ minimumFractionDigits: 2 });
    }
}