<template>
    <div class="coin-setting">
        <!-- <div class="header-title">{{$t("Coin Setting")}}</div> -->
        <div class="header-title overflow-auto">
            <div class="col-12 d-flex flex-fill align-items-center pl-0">
                <b>{{ $t("Coin Setting") }}</b>
                <div class="d-flex mx-0">
                    <div class="col">
                        <button class="activate-btn" :class="{ 'trx-active': network == 'trx' }"
                            @click="changeNetwork('trx')">
                            <inline-svg :src="require('../assets/icon/networks/trx.svg')" width="25" height="25"
                                aria-label="copy"></inline-svg>
                            <b class="ml-2">{{ $t("TRX") }}</b>
                        </button>
                    </div>

                    <div class="col">
                        <button class="activate-btn" :class="{ 'eth-active': network == 'eth' }"
                            @click="changeNetwork('eth')">
                            <inline-svg :src="require('../assets/icon/networks/eth.svg')" width="25" height="25"
                                aria-label="copy"></inline-svg>
                            <b class="ml-2">{{ $t("ETH") }}</b>
                        </button>
                    </div>

                    <div class="col">
                        <button class="activate-btn" :class="{ 'bsc-active': network == 'bsc' }"
                            @click="changeNetwork('bsc')">
                            <inline-svg :src="require('../assets/icon/networks/bsc.svg')" width="25" height="25"
                                aria-label="copy"></inline-svg>
                            <b class="ml-2">{{ $t("BSC") }}</b>
                        </button>
                    </div>

                </div>
            </div>
        </div>
        <div class="trx-table px-3">
            <b-table tbody-tr-class="rowClass" :items="coin_setting" :fields="fields" show-empty fixed
                empty-filtered-text="No Data">
                <!-- start table header -->
                <template v-slot:head(token)>
                    {{ $t("COIN") }}
                </template>
                <template v-slot:head(threshold)>
                    {{ $t("THRESHOLD") }}
                </template>
                <template v-slot:head(edit)>
                    <div class="text-center">
                        {{ $t("EDIT") }}
                    </div>
                </template>
                <!-- end table header -->

                <!-- start table body -->
                <template #cell(token)="row">
                    <img :src="row.item.token.image" alt="savvix-coin-icon" width="30px" height="30px">
                    <span class="ml-2">{{ row.item.token.symbol }}</span>
                </template>
                <template #cell(price)="row">
                    ${{ formatNum(row.item.token.price, row.item.token.price_decimal) }}
                </template>

                <template #cell(threshold)="row">
                    {{ formatNum(row.item.threshold, row.item.token.volume_decimal) }}
                </template>

                <template #cell(edit)="row">
                    <div class="text-center">
                        <!-- {{ $t("EDIT") }} -->
                        <inline-svg stroke="var(--primary)" :src="require('../assets/icon/edit.svg')" aria-label="edit"
                            id="edit" @click="showEditCoinSettingModal(row.item)"></inline-svg>
                    </div>
                </template>
                <!-- end table body -->
            </b-table>
        </div>


        <!-- Update Threshold Modal -->
        <b-modal id="edit-coin-setting-modal" ref="edit-coin-setting-modal" hide-footer
            :title="`Edit ${coinSettingForm.symbol} Setting`" centered>
            <div class="d-flex flex-column text-center">
                <form id="update-threshold-form" class="modal-form" @submit.stop.prevent="updateCoinSetting">
                    <div class="form-group">
                        <label for="input-live">{{ $t("Threshold") }}</label>
                        <b-form-input id="example-input-1" :placeholder="$t(`Enter threshold`)"
                            v-model="$v.coinSettingForm.threshold.$model"
                            :state="validateState('threshold', 'coinSettingForm')" aria-describedby="input-1-live-feedback"
                            type="number" :step="1 / Math.pow(10, coinSettingForm.volume_decimal)"></b-form-input>
                        <b-form-invalid-feedback id="input-1-live-feedback">{{
                            $t("Please key in number")
                        }}</b-form-invalid-feedback>
                    </div>

                    <div class="form-group">
                        <div class="mt-5 mb-5 w-100">
                            <button type="submit" class="submit-btn">{{ $t("Edit") }}</button>
                        </div>
                    </div>
                </form>
            </div>
        </b-modal>

    </div>
</template>

<script>
import api from '../helper/api';
import InlineSvg from "vue-inline-svg";
import coinHelper from "../helper/coin.js";

export default ({
    name: "CoinSetting",
    components: {
        InlineSvg
    },
    data() {
        return {
            coin_setting: null,
            fields: [
                { key: "token", label: "COIN" },
                { key: "price", label: "PRICE" },
                { key: "threshold", label: "THRESHOLD" },
                { key: "edit", label: "EDIT" },
            ],
            network: 'trx',
            coinSettingForm: {
                symbol: "",
                threshold: "",
                volume_decimal: "",
                api_symbol : ""
            }
        }
    },
    validations: {
        coinSettingForm: {
            threshold: {
                // required,
            },
        },
    },
    mounted() {
        this.getThreshold();
    },
    methods: {
        validateState(name, form) {
            const { $dirty, $error } = this.$v[form][name];
            return $dirty ? !$error : null;
        },

        async getThreshold() {
            try {
                const response = await api.apiCall(this.network, 'GET', '/token-globals/get-all');
                this.coin_setting = response.message;

                console.log(response);
            } catch (error) {
                console.error(error);
            }
        },

        changeNetwork(network) {

            if (network == this.network) {
                return;
            }

            this.network = network;
            this.getThreshold();
        },

        showEditCoinSettingModal(data) {

            this.coinSettingForm = {
                symbol: data.token.symbol,
                api_symbol: data.token.api_symbol,
                threshold: coinHelper.formatNumber(data.threshold, data.token.volume_decimal),
                volume_decimal: data.token.volume_decimal
            }

            this.$refs['edit-coin-setting-modal'].show();
        },

        async updateCoinSetting() {
            try {
                let params = {
                    api_symbol : this.coinSettingForm.api_symbol,
                    threshold : this.coinSettingForm.threshold * 1
                };
                await api.apiCall(
                    this.network,
                    "POST",
                    "/token-globals/update-token",
                    params
                );
                this.$swal({
                    text: this.$t(`Update ${this.coinSettingForm.symbol} success`),
                    icon: "success",
                });


                this.getThreshold();
                
            } catch (error) {
                console.error(error);
                this.$swal({
                    text: error.message,
                    icon: "error",
                });
            }
        },

        formatNum(num, decimal) {
            return coinHelper.formatNumberWithCommas(num, decimal);
        },
    }
});
</script>

<style>
@import '../css/styles.css';

.coin-setting .header-title .activate-btn {
    min-width: 200px;
    padding: 5px;
}

.coin-setting .header-title .activate-btn svg {
    margin-bottom: 2px;
}

.coin-setting .activate-btn.trx-active {
    background-color: #f0002833;
    color: #ef0027;
    border: 1px solid #ef0027 !important;
}

.coin-setting .activate-btn.eth-active {
    background-color: #627eea33;
    color: #627eea;
    border: 1px solid #627eea !important;
}

.coin-setting .activate-btn.bsc-active {
    background-color: #f3ba2e33;
    color: #f3ba2e;
    border: 1px solid #f3ba2e !important;
}

.trx-body {
    height: 100%;
    overflow: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.trx-body::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.trx-body {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.table-responsive {
    display: inline-table;
}

.trx-table {
    font-size: 13px;
    text-align: center;
    margin-top: 0.5rem;
    /* padding-left: 0.5rem */
}

.trx-table th,
.trx-table td {
    font-size: 16px;
    border: 1px !important;
    text-align: left;
    /* padding: 1px 20px; */
}

.trx-table table {
    border-collapse: separate;
    border-spacing: 0 8px;
}

.trx-table td {
    border-bottom: none;
    vertical-align: middle;
}

.trx-table td:first-child {
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
}

.trx-table td:last-child {
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
}

.rowClass {
    background-color: var(--light);
}

.trx-table.b-table-has-details {
    background-color: aliceblue;
}

.rowClass:hover {
    cursor: pointer;
    background-color: aliceblue;
}

.trx-table.b-table-details:hover {
    cursor: auto;
    background-color: white;
}
</style>